import React from 'react';
import './style.scss';
import Navbar from './navbar';
import MyImage from '../elements/MyImage';

const Header = () => (
	<section className="hero gradientBg is-fixed-top">
		<div className="hero-body">
			<div className="container center">
				<article className="media">
					<div className="media-left">
						<div className="column is-1"></div>
					</div>
					<div className="media-content">
						<h1 className="is-size-1 has-text-white is-family-secondary">
							ANNE DINDAR VALIENTE
						</h1>
						<nav className="level">
							<div className="level-item">
								<h1 className="subtitle is-4 has-text-white is-family-secondary">
									Superviseure
								</h1>
							</div>
							<div className="level-item">
								<h1 className="subtitle is-4 has-text-white is-family-secondary">
									Gestalt-thérapeute
								</h1>
							</div>
							<div className="level-item">
								<h1 className="subtitle is-4 has-text-white is-family-secondary">
									Formatrice
								</h1>
							</div>
						</nav>
					</div>
					<MyImage size="media-right is-128x128" src="zebre" alt="zebre" rounded={true} />
				</article>
			</div>
		</div>
		<Navbar />
	</section>
);

export default Header;