import React, {useState} from 'react';
import { Link } from "gatsby";
import './style.scss';

const Navbar = () => 
{
	const [isMenuOpen, setIsMenuOpen] = useState(false);
  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }


	return(
		<div className="hero-head">
			<nav className="navbar has-text-white gradientBg">
				<div className="container">
					<div className="navbar-brand">
          				<Link className="navbar-item is-family-secondary is-size-4" to='/'>
            				Accueil
          				</Link>
						<a 
							role="button" 
							className={"navbar-burger burger" + (isMenuOpen ? " is-active" : "")}
							aria-label="menu" 
							aria-expanded="false" 
							data-target="navbarBasicExample"
							onClick={openMenu}
						>
							<span aria-hidden="true"></span>
							<span aria-hidden="true"></span>
							<span aria-hidden="true"></span>
						</a>
					</div>

					<div id="navbarBasicExample" className={"navbar-menu" + (isMenuOpen ? " is-active" : "")}>
						<div className="navbar-start">
							<Link className="navbar-item is-family-secondary is-size-4" to='/presentation'>
								Présentation
							</Link>
							<Link className="navbar-item is-family-secondary is-size-4" to='/la_Supervision'>
								La Supervision
							</Link>
							<Link className="navbar-item is-family-secondary is-size-4" to='/quatres_therapies'>
								Quatres Thérapies
							</Link>
							<Link className="navbar-item is-family-secondary is-size-4" to='/groupes_residentiels'>
								Groupes résidentiels
							</Link>
							<Link className="navbar-item is-family-secondary is-size-4" to='/pour_mediter'>
								Pour méditer
							</Link>
							<Link className="navbar-item is-family-secondary is-size-4" to='/deontologie'>
								Déontologie
							</Link>
							<Link className="navbar-item is-family-secondary is-size-4" to='/contact'>
								Contact
							</Link>
						</div>
					</div>
				</div>
			</nav>
		</div>
	)
};

export default Navbar;