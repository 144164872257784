import React from "react";
import MyImage from "./MyImage";

function Box({ children, height=false, hoverable=false, width=true ,background="", reference=null}) {
    let classNames = "box";
    if (height) {
        classNames += " heightMax";
    }
    if (hoverable) {
        classNames += " hoverable";
    }
    if (width) {
        classNames += " widthMax";
    }
    if (background !== "") {
        classNames += (" has-background-" + background)
    }
    return (
        <div className={classNames} ref={reference}>
            {children}
        </div>
    );
}

function BoxTitle({ title }) {
    return(
        <div className="content">
            <p className="title is-4 is-family-secondary centered-content">{title}</p>
        </div>
    );
}

function BoxImage({ size = "", src, alt, hoverable=false, rounded=false }) {
    return (
        <MyImage size={size} src={src} alt={alt} hoverable={hoverable} rounded={rounded}/>
    );
}

function BoxIcon({ icon }) {
    return(
        <div className="content centered-content">
            <span className="icon">
                    <i className={`fas ${icon} fa-3x is-flex`}></i>
            </span>
        </div>

    );
}

function BoxParagraph({ children }) {
    return(
        <div className="content centered-content">
            {children}
        </div>
    );
}

function BoxButton({children, functionClick=()=>{}}) {
    return(
        <button className="button is-primary is-light is-fullwidth is-rounded" onClick={functionClick}>
            {children}
        </button>
    )
}

function Bibliobox({title="", references="", description="", background="primary-light"}) {
    return(
        <Box background={background} height={true}>
            <p><b>{title}</b></p>
            <p><em>{references}</em></p>
            <p>{description}</p>
        </Box>
    )

}

export { Box, BoxTitle, BoxImage, BoxIcon, BoxParagraph, BoxButton, Bibliobox};
export default Box;