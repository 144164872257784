import React from 'react';
import './style.scss';
import Helmet from './helmet';
import Header from './header';
import Footer from './footer';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Layout = ({ children }) => (
	<div>
		<Helmet />
		<Header />
		<section className="section">
			<div className="columns">
                <div className="column is-offset-1 is-10">
					{children}
				</div>
			</div>
		</section>
		<Footer />
	</div>
);

export default Layout;
