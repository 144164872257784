import React from 'react';
import './style.scss';
import background from '../images/rayures_zebre.png';
import Box, { BoxParagraph, BoxImage } from '../elements/box';

const Footer = () => (
    <>
      <footer className='footer center gradientBg'>
        <div className="content">
          <div className='content has-text-centered'>
            <p>
              <b><em>" Est-ce qu'une fleur, pleine de lumière, de beauté, dit :
                Je donne, j'aide, je sers ?
                Elle est !
                Et parce qu'elle n'essaie pas de faire quelque chose, elle recouvre la terre."
              </em></b>
            </p>
            <p><b>
              Krishnamurti
              </b></p>
            <p>---</p>
          </div>
          <div className="columns">
            <div className="column">
              <Box background="primary" height="true">
                <BoxParagraph>
                  <p>Cabinet situé 100 rue de l'Ouest, 75014 PARIS --- Métro Pernety sur la ligne 13</p>
                  <p>Tél : 06 80 58 32 86</p>
                  <p>Mail : psy.annedindarvaliente@gmail.com</p>
                  <div className="columns is-centered is-gapless">
                    <div className="column is-2">
                      <p>Instagram : </p>
                    </div>
                    <div className="column is-2">
                      <a href="https://www.instagram.com/psyandiva/?hl=fr">@psyandiva</a>
                    </div>
                    <div className="column is-2">
                      <BoxImage size="is-24x24" src="instagram" alt="instagram" hoverable={true}/>
                    </div>
                  </div>
                </BoxParagraph>
              </Box>
            </div>
            <div className="column">
              <Box background="primary" height="true">
                <BoxParagraph>
                  <p>Site réalisé par : Anne-Sylvie Chollet-Karraz --- contact : annesylvie.chollet@gmail.com</p>
                  <p>Illustrations par : Jean-Marie CELBERT --- contact : jmcelbert@gmail.com</p>
                  <p>Dessins de mains par : Anastasia YANOUCHEVES --- contact : anastasia.yanouchevsky@gmail.com</p>
                </BoxParagraph>
              </Box>
            </div>
          </div>
        </div>
      </footer>
      <footer 
        className="footer"
        style={{ 
          backgroundImage:`url(${background})`,
          minHeight:"150px",
          backgroundSize:"cover"
        }}
      >
      </footer>
    </>
)

export default Footer
