import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function MyImage({ size = "", hoverable=false, rounded=false, src, alt }) {
    const data = useStaticQuery(graphql`
    query {
        allFile {
          edges {
            node {
              name
              publicURL
            }
          }
        }
    }
  `)
    const myimg = data.allFile.edges.find(obj => obj.node.name === src);
    let classNames="";
    if (hoverable) {
        classNames +=" hoverable ";
    }
    if (rounded) {
        classNames +=" is-rounded ";
    }
    return (
        <figure className={"image " + size}>
            <img
                className={classNames}
                src={myimg.node.publicURL}
                alt={alt}
            />
        </figure>
    );
}

export default MyImage;